import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableTitleSkltn from "../components/Skeloton/TableTitleSkltn";
import { DownArrowIcon } from "../icons";
import moment from "moment";

export const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
export const restrictedChars = /[&<>"'\\]/;
export const specialChars = /[!@#$%^&*(),.?":{}|<>]/;
export const pageSize = 6;

export const getPhoneNumberLength = (country) => {
  const contactLength = country?.format?.replace(/[\s-+()]/g, "")?.length;
  const dialCodeLength = country?.dialCode?.length + "".length;
  return contactLength - dialCodeLength;
};

export const GetTableTitle = ({ title, isLoading, totalCount }) => {
  const [details, setDetails] = useState({ pathFrom: "", total: "" });

  useEffect(() => {
    switch (title) {
      case "partner":
        setDetails({
          pathFrom: "Partner Details",
          total: "Total Partners",
        });
        break;
      case "site":
        setDetails({
          pathFrom: "Site Details",
          total: "Total Sites",
        });
        break;
      case "camera":
        setDetails({
          pathFrom: "Camera Details",
          total: "Total Cameras",
        });
        break;
      case "upload":
        setDetails({
          pathFrom: "Uploded Document List",
          total: "Total Documents",
        });
        break;
      default:
        break;
    }
  }, [title]);

  return (
    <div className="py-4 px-5 text-headerText font-semibold text-sm flex items-center bg-white rounded-t-xl">
      {isLoading ? (
        <div className="flex gap-2">
          <TableTitleSkltn />
        </div>
      ) : (
        <>
          <span className="">{details.pathFrom}</span>
          <DownArrowIcon className="-rotate-90" />
          <span className="">{details.total}:</span>
          <span className="text-sidebarText pl-1">{totalCount}</span>
        </>
      )}
    </div>
  );
};
